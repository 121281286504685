
.my-menu{
    display: none;
    overflow-y: hidden;
}
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }
  .menuButton{
    text-align: center;
     background-color: white; 
     padding: 15px;
     width: 150px;
     border-radius: 27px;
     cursor: pointer;
     border-style: none;
     letter-spacing: 1.2px;
  }
  
  .bm-burger-bars {
    background: white;
  }
  .bm-burger-bars{
      display: none;
      cursor:none;
  }
  
  /* .bm-menu-wrap {
    visibility: hidden;
  }
  
  .bm-menu-wrap.open {
    visibility: visible;
  } */
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
    float: left;
  }

  .bm-cross {
    background: #bdc3c7;
  }
  .bm-menu {
    box-sizing: border-box;
    height: 100vh;
    background: black;
    overflow-y: hidden;
    /* padding: 50% 0px 50% 0px; */
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    /* align-items: center; */
    font-size: 20px;
  }
  
  .bm-item-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
    /* padding: 0.8em; */
  }
  .bm-item {
    color: #bdc3c7;
    text-decoration: none;
    margin-top: 20px;
  }
  @media only screen and (max-width: 960px) {
    .my-menu{
        display: block;
    }
    .bm-burger-bars{
        display: flex;
        cursor: pointer;
    }
  }