
 .navbar{
     
     background-color: black;
     color: #fff;
     display: flex;
     align-items: center;
     width: 100%;
     height: 80px;
     top: 0;
     left: 0;
     z-index: 15;
     position: fixed;
     padding: 50px;
      box-sizing: border-box; 
     justify-content: space-between;
     font-size: 14px;
     letter-spacing: 1.2px;
 }
 .navbar.active{
     background-color: black;
 }
 
 .menu{
     display: flex;
     justify-content: flex-end;
     align-items: center;
 }
 .menu ul{
     text-align: center;
     list-style: none;
     display: flex;
     padding-left: 0;
 }

 .menu li {
     display: flex;
     align-items: center;
     margin-left: 30px
 }
 .menu li:hover{
  color: #6495ED;
  -webkit-transition: color 100ms linear;
  -ms-transition: color 100ms linear;
  transition: color 100ms linear;
  cursor: pointer;
 }
 .logo p{
     font-size:10px;
     letter-spacing: 1.2px;
 }
 .logo{
     cursor: pointer;
     display: flex;
     justify-content: flex-start;
     background-image:url(../../data/images/privat.png);
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;
     width: 300px;
     height: 200px;
     padding: 20px;
 }
 .logo h2{
     font-size: 24px;
 }

 .menu button{
     text-align: center;
     background-color: white; 
     padding: 15px;
     width: 150px;
     border-radius: 27px;
     cursor: pointer;
     border-style: none;
     letter-spacing: 1.2px;
 }
 .menu button:hover{
     background-color: #6495ED;
     -webkit-transition: background-color 100ms linear;
     -ms-transition: background-color 100ms linear;
     transition: background-color 100ms linear;
 }
.bars{
    display: none;
}
.mobileButton{
    display: none;
}
 @media only screen and (max-width: 960px) {
    .menu{
     display: none; 
    /* position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer; */

   }
   .mobileButton{
       display: block;
       width:50px;
       text-align: center;
       background-color: white; 
       padding: 15px;
       width: 150px;
       border-radius: 27px;
       cursor: pointer;
       border-style: none;
       letter-spacing: 1.2px;
       
   }
   .navbar{
       padding: 60px 10px 50px 10px;
       justify-content: space-between;
       align-items: center;
   }
   .logo{
    width: 150px;
    height: 150px;
    align-items: center;
   }
 }