.container{
 display: flex;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
    height: fit-content; 
    margin: 50px;
     position: relative; 
    overflow-y: hidden;
        
      }

.contactRight{
    
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    position: relative;
    top: 100px;
}
.contactRight form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 
#standard-basic{
    width: 500px;

}
.titleContainer{
    height:fit-content;
    background-color: transparent;
    position: absolute;
    display: block;
    top: 100;
    color: #F2F7FB;
    /* background-color: transparent; */
    cursor: default;
     float: top;
}

.titleText{
    height:fit-content;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    font-size: 119px;
    line-height: 146px;
    letter-spacing: .175em;
    text-transform: uppercase;
}




.formContainer{
    margin-bottom: 50px
}
.iconContainer{
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.iconContainer p{
    font-size: 12px;
    margin: 20px;

}
.contactLeft{
    top: 100px;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position:relative;
}
@media (max-width: 768px){

.titleContainer {
    display: none;
}

.container{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height:fit-content;
    overflow-y: auto;
 
}
.contactRight{
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: fit-content;
    height: fit-content; 
}
.contactLeft{
    width: fit-content;
    height: fit-content; 
}
#standard-basic{
    width: 50vw;

}
}